body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
}

#root {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  border: none;
}
